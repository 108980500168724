.span {
  background-color: transparent;
  border: none;
  display: inline-block;
  cursor: default;
}
.span:hover {
  cursor: default;
  color: #689ada;
  animation: animate 0.6s;
}
@keyframes animate {
  25% {
    transform: scale(0.8, 1.3);
  }
  50% {
    transform: scale(1.1, 0.8);
  }
  75% {
    transform: scale(0.7, 1.2);
  }
}
